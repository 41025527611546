import {ChangeDetectorRef, Injectable, NgZone} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import {StateService} from "../../core/state.service";

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  translations: Map<string, any> = new Map();
  customTranslations: Map<string, any> = new Map();

  constructor(private readonly http: HttpClient,
              private readonly stateService: StateService) {
  }

  translateArgs(key: string, ...args: string[]): string {
    try {
      return this.replaceArgsInTranslation(this.getTranslation(key), ...args);
    } catch (e) {
      return key;
    }
  }

  translate(key: string): string {
    try {
      return this.getTranslation(key);
    } catch (e) {
      return key;
    }
  }

  async init() {
    await this.fetchTranslations();
    await this.fetchCustomTranslations();
  }

  private getTranslation(keyString: string): string {
    const keyPath: string[] = keyString.split('.');
    let value = [this.getTranslations(false, true), this.stateService.theme, ...keyPath]
      .reduce((result: string, key: any) =>  result[key] ?? keyString);
    if (value != keyString) {
      return value;
    }
    value = [this.getTranslations(true, true), this.stateService.theme, ...keyPath]
      .reduce((result: string, key: any) =>  result[key] ?? keyString);
    if (value != keyString) {
      return value;
    }
    value = [this.getTranslations(false, false), ...keyPath]
      .reduce((result: string, key: any) =>  result[key] ?? keyString);
    if (value != keyString) {
      return value;
    }
    return [this.getTranslations(true, false), ...keyPath]
      .reduce((result: string, key: any) =>  result[key] ?? keyString);
  }

  private getTranslations(forceEnglish: boolean, useCustom: boolean) {
    const language = forceEnglish || (!this.stateService.user?.language || this.stateService.user?.language === 'xx')
      ? 'en' : this.stateService.user?.language;
    return (useCustom ? this.customTranslations : this.translations).get(language);
  }

  private replaceArgsInTranslation(str: string, ...args: string[]) {
    if (args.length === 0 || !str) {
      return '';
    }
    const mapObj: { [key: string]: string } = {};
    args.forEach((arg, idx) => (mapObj['#args' + idx] = arg));
    const regExp: RegExp = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    return str.replace(regExp, matched => mapObj[matched.toLowerCase()]);
  }

  private async fetchTranslations() {
    const queryParam = `?v=11`;
    const languages = ['en', 'it', 'de', 'es', 'fr', 'tr'];

    const requests = languages.map(lang =>
      firstValueFrom(this.http.get(`/assets/lang/${lang}.json${queryParam}`))
        .then(translation => this.translations.set(lang, translation))
    );

    await Promise.all(requests);
  }


  private async fetchCustomTranslations() {
      const queryParam = `?v=1`;
      const languages = ['en', 'it', 'de', 'es', 'fr', 'tr'];

      const requests = languages.map(lang =>
        firstValueFrom(this.http.get(`/assets/lang/${lang}_custom.json${queryParam}`))
          .then(translation => this.customTranslations.set(lang, translation))
      );

      await Promise.all(requests);
    }

  }
