import {Injectable} from '@angular/core';
import {StateService} from "./state.service";

export interface Theme {
  primaryColor: string;
  backgroundColor: string;
  background2Color: string;
  fontColor: string;
  oddsColor: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private readonly stateService: StateService) {
  }

  goldbetTheme: Theme = {
    primaryColor: '#FFCC00',
    backgroundColor: '#ffffff',
    background2Color: '#141B4D',
    fontColor: '#000000',
    oddsColor: '#1BB14C'
  };

  promptbetTheme: Theme = {
    primaryColor: '#0090ff',
    backgroundColor: '#ffffff',
    background2Color: '#141B4D',
    fontColor: '#000000',
    oddsColor: '#1BB14C'
  };

  betterTheme: Theme = {
    primaryColor: '#1bb14c',
    backgroundColor: '#ffffff',
    background2Color: '#141B4D',
    fontColor: '#000000',
    oddsColor: '#1BB14C'
  };

  betflagTheme: Theme = {
    primaryColor: '#42aee8',
    backgroundColor: '#003960',
    background2Color: '#141B4D',
    fontColor: '#F2F2F2',
    oddsColor: '#1BB14C'
  };

  bet2dayTheme: Theme = {
    primaryColor: '#EE8201',
    backgroundColor: '#000000',
    background2Color: '#757575',
    fontColor: '#F2F2F2',
    oddsColor: '#EE8201'
  }

  public injectTheme() {
    let theme: Theme;
    if (this.stateService.user?.client?.theme === 'goldbet') {
      this.stateService.theme = 'goldbet';
      this.stateService.clientLogo = 'assets/logos/goldbet.svg';
      this.stateService.launchWithText = false;
      theme = this.goldbetTheme;
    } else if (this.stateService.user?.client?.theme === 'better') {
      this.stateService.theme = 'better';
      this.stateService.clientLogo = 'assets/logos/better.png';
      this.stateService.launchWithText = false;
      theme = this.betterTheme;
    } else if (this.stateService.user?.client?.theme === 'betflag') {
      this.stateService.theme = 'betflag';
      this.stateService.clientLogo = 'assets/logos/betflag-dark.png';
      this.stateService.launchWithText = false;
      theme = this.betflagTheme;
    } else if (this.stateService.user?.client?.theme === 'bet2day') {
      this.stateService.theme = 'bet2day';
      this.stateService.clientLogo = 'assets/logos/bet2day-dark.png';
      this.stateService.showPoweredBy = false;
      this.stateService.launchWithText = false;
      theme = this.bet2dayTheme;
    } else {
      this.stateService.theme = 'promptbet';
      this.stateService.clientLogo = 'assets/logos/promptbet.png';
      theme = this.promptbetTheme;
    }
    const existingStyleEl = document.getElementById('dynamic-theme-style');
    if (existingStyleEl) {
      existingStyleEl.remove();
    }

    const styleEl = document.createElement('style');
    styleEl.id = 'dynamic-theme-style';
    styleEl.textContent = `:root {
      --primary-color: ${theme.primaryColor};
      --background-color: ${theme.backgroundColor};
      --background2-color: ${theme.background2Color};
      --font-color: ${theme.fontColor};
      --odds-color: ${theme.oddsColor};
    }
  `;
    console.log('STYLE', this.stateService.theme, styleEl);
    document.head.appendChild(styleEl);
  }
};
