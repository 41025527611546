import {Injectable} from '@angular/core';
import {BettingMarket, PromptResult, SportsEvent} from "../capture/capture.service";
import {UserInfo} from "../userInfo";
import {SelectedBet} from "./selected-bet";

@Injectable({
  providedIn: 'root'
})
export class StateService {
  // include registerLocaleData in app.module when expanding this list
  public static readonly supportedLanguages = ['it', 'en', 'de', 'es', 'fr', 'tr', 'xx'];
  theme: 'goldbet' | 'better' | 'promptbet' | 'betflag' | 'bet2day' = 'promptbet';
  clientLogo = 'assets/logos/promptbet.png';
  showPoweredBy = true;
  languages = StateService.supportedLanguages;
  isNative = false;
  submitted = false;
  testPrompt?: string;
  launchWithText = true;

  token?: string
  user?: UserInfo;

  selection: SelectedBet[] = [];
  result!: PromptResult;

  selectedMatch?: SportsEvent;
  previousPromptResult?: PromptResult;

  constructor() {
  }

  public setResult(res: PromptResult, othersLabel: string) {
    res.events.forEach(event => {
      event.markets.forEach(this.processMarkets(othersLabel));
      event.comboMarkets.forEach(this.processMarkets(othersLabel));
    });
    res.events.sort((m1, m2) => {
      return new Date(m1.startTs).getTime() - new Date(m2.startTs).getTime();
    });
    this.result = res;
  }

  private processMarkets(othersLabel: string) {
    return (m: BettingMarket) => {
      m.lines.forEach(line => {
        if (line.name?.toLowerCase() === 'others') {
          line.name = othersLabel;
        }
      });
      m.previewLines = m.lines.slice(0, 6);
    };
  }

  isSelected(id: string) {
    return this.selection.some(sel => sel.line.uuid === id);
  }
}

