import {Injectable} from '@angular/core';
import {EventService} from "../shared/event.service";
import {EventType} from "../shared/event-type";
import {SelectedBet} from "./selected-bet";

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(private eventService: EventService) {
  }

  close(reason: 'consent-declined' | 'exit' | 'completed') {
    this.eventService.createEvent(EventType.CLICK_CLOSE, reason);
    this.emitIframeMessage('close', {reason});
  }

  startRecording() {
    this.emitIframeMessage('mic-start');
  }

  endRecording(status: 'completed' | 'timeout' | 'abort') {
    this.emitIframeMessage('mic-end', {status});
  }

  private emitIframeMessage(type: 'bet' | 'consent' | 'close' | 'mic-start' | 'mic-end' | 'log',
                            data: { [key: string]: any } = {}) {
    const messageData = {
      type,
      ...data
    };
    console.log('emit iframe message', messageData);
    const webkit = (window as any).webkit;
    const android = (window as any).Android;
    if (webkit?.messageHandlers?.promptbet) {
      webkit?.messageHandlers?.promptbet?.postMessage(messageData);
    } else if (android) {
      android?.postMessage(JSON.stringify(messageData));
    } else if (window.parent) {
      window.parent.postMessage(messageData, '*');
    }
  }

  logToApp(message: string) {
    this.emitIframeMessage('log', {message});
  }

  askConsent() {
    this.emitIframeMessage('consent');
  }

  submitBets(bets: SelectedBet[]) {
    this.emitIframeMessage('bet', {bets: bets.map(b => ({
        ...b.match.metadata,
        ...b.line.metadata
      }))});
  }
}
